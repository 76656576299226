import React, { Suspense, useEffect, useRef, useState, useMemo } from "react";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import {
  useGLTF,
  useTexture,
  Loader,
  Environment,
  useFBX,
  useAnimations,
  OrthographicCamera,
} from "@react-three/drei";
import { MeshStandardMaterial } from "three/src/materials/MeshStandardMaterial";

import { LinearEncoding, sRGBEncoding } from "three/src/constants";
import { LineBasicMaterial, MeshPhysicalMaterial, Vector2 } from "three";
import ReactAudioPlayer from "react-audio-player";

import createAnimation from "./converter";
import blinkData from "./blendDataBlink.json";

import * as THREE from "three";
import axios from "axios";

const _ = require("lodash");

// const host = "http://localhost:3001";
const host = "https://app.archiveye.ai/humanbe";

function Avatar({
  avatar_url,
  speak,
  setSpeak,
  text,
  setAudioSource,
  playing,
  audio,
  endRecording,
  setEndRecording,
  loading,
  setLoading,
}) {
  let gltf = useGLTF(avatar_url);
  let morphTargetDictionaryBody = null;
  let morphTargetDictionaryLowerTeeth = null;

  const [
    bodyTexture,
    eyesTexture,
    teethTexture,
    bodySpecularTexture,
    bodyRoughnessTexture,
    bodyNormalTexture,
    teethNormalTexture,
    // teethSpecularTexture,
    hairTexture,
    tshirtDiffuseTexture,
    tshirtNormalTexture,
    tshirtRoughnessTexture,
    hairAlphaTexture,
    hairNormalTexture,
    hairRoughnessTexture,
  ] = useTexture([
    "/images/body.webp",
    "/images/eyes.webp",
    "/images/teeth_diffuse.webp",
    "/images/body_specular.webp",
    "/images/body_roughness.webp",
    "/images/body_normal.webp",
    "/images/teeth_normal.webp",
    // "/images/teeth_specular.webp",
    "/images/h_color.webp",
    "/images/tshirt_diffuse.webp",
    "/images/tshirt_normal.webp",
    "/images/tshirt_roughness.webp",
    "/images/h_alpha.webp",
    "/images/h_normal.webp",
    "/images/h_roughness.webp",
  ]);

  _.each(
    [
      bodyTexture,
      eyesTexture,
      teethTexture,
      teethNormalTexture,
      bodySpecularTexture,
      bodyRoughnessTexture,
      bodyNormalTexture,
      tshirtDiffuseTexture,
      tshirtNormalTexture,
      tshirtRoughnessTexture,
      hairAlphaTexture,
      hairNormalTexture,
      hairRoughnessTexture,
    ],
    (t) => {
      t.encoding = sRGBEncoding;
      t.flipY = false;
    }
  );

  bodyNormalTexture.encoding = LinearEncoding;
  tshirtNormalTexture.encoding = LinearEncoding;
  teethNormalTexture.encoding = LinearEncoding;
  hairNormalTexture.encoding = LinearEncoding;

  gltf.scene.traverse((node) => {
    if (
      node.type === "Mesh" ||
      node.type === "LineSegments" ||
      node.type === "SkinnedMesh"
    ) {
      node.castShadow = true;
      node.receiveShadow = true;
      node.frustumCulled = false;

      if (node.name.includes("Body")) {
        node.castShadow = true;
        node.receiveShadow = true;

        node.material = new MeshPhysicalMaterial();
        node.material.map = bodyTexture;
        // node.material.shininess = 60;
        node.material.roughness = 1.7;

        // node.material.specularMap = bodySpecularTexture;
        node.material.roughnessMap = bodyRoughnessTexture;
        node.material.normalMap = bodyNormalTexture;
        node.material.normalScale = new Vector2(0.6, 0.6);

        morphTargetDictionaryBody = node.morphTargetDictionary;

        node.material.envMapIntensity = 0.8;
        // node.material.visible = false;
      }

      if (node.name.includes("Eyes")) {
        node.material = new MeshStandardMaterial();
        node.material.map = eyesTexture;
        // node.material.shininess = 100;
        node.material.roughness = 0.1;
        node.material.envMapIntensity = 0.5;
      }

      if (node.name.includes("Brows")) {
        node.material = new LineBasicMaterial({ color: 0x000000 });
        node.material.linewidth = 1;
        node.material.opacity = 0.5;
        node.material.transparent = true;
        node.visible = false;
      }

      if (node.name.includes("Teeth")) {
        node.receiveShadow = true;
        node.castShadow = true;
        node.material = new MeshStandardMaterial();
        node.material.roughness = 0.1;
        node.material.map = teethTexture;
        node.material.normalMap = teethNormalTexture;

        node.material.envMapIntensity = 0.7;
      }

      if (node.name.includes("Hair")) {
        node.material = new MeshStandardMaterial();
        node.material.map = hairTexture;
        node.material.alphaMap = hairAlphaTexture;
        node.material.normalMap = hairNormalTexture;
        node.material.roughnessMap = hairRoughnessTexture;

        node.material.transparent = true;
        node.material.depthWrite = false;
        node.material.side = 2;
        node.material.color.setHex(0x000000);

        node.material.envMapIntensity = 0.3;
      }

      if (node.name.includes("TSHIRT")) {
        node.material = new MeshStandardMaterial();

        node.material.map = tshirtDiffuseTexture;
        node.material.roughnessMap = tshirtRoughnessTexture;
        node.material.normalMap = tshirtNormalTexture;
        node.material.color.setHex(0xffffff);

        node.material.envMapIntensity = 0.5;
      }

      if (node.name.includes("TeethLower")) {
        morphTargetDictionaryLowerTeeth = node.morphTargetDictionary;
      }
    }
  });

  const [clips, setClips] = useState([]);
  const mixer = useMemo(() => new THREE.AnimationMixer(gltf.scene), []);

  useEffect(() => {
    if (speak === false) return;

    makeSpeech(text)
      .then((response) => {
        let { blendData, filename } = response.data;

        let newClips = [
          createAnimation(blendData, morphTargetDictionaryBody, "HG_Body"),
          createAnimation(
            blendData,
            morphTargetDictionaryLowerTeeth,
            "HG_TeethLower"
          ),
        ];

        filename = host + filename;

        setClips(newClips);
        setAudioSource(filename);
      })
      .catch((err) => {
        console.error(err);
        setSpeak(false);
      });
  }, [speak]);

  //
  useEffect(() => {
    if (endRecording === false) return;

    makeSpeechNew({ text, audio })
      .then((response) => {
        let { blendData, filename } = response.data;

        let newClips = [
          createAnimation(blendData, morphTargetDictionaryBody, "HG_Body"),
          createAnimation(
            blendData,
            morphTargetDictionaryLowerTeeth,
            "HG_TeethLower"
          ),
        ];

        filename = host + filename;

        setClips(newClips);
        setAudioSource(filename);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setEndRecording(false);
      });
  }, [endRecording]);

  let idleFbx = useFBX("/idle.fbx");
  let { clips: idleClips } = useAnimations(idleFbx.animations);

  idleClips[0].tracks = _.filter(idleClips[0].tracks, (track) => {
    return (
      track.name.includes("Head") ||
      track.name.includes("Neck") ||
      track.name.includes("Spine2")
    );
  });

  idleClips[0].tracks = _.map(idleClips[0].tracks, (track) => {
    if (track.name.includes("Head")) {
      track.name = "head.quaternion";
    }

    if (track.name.includes("Neck")) {
      track.name = "neck.quaternion";
    }

    if (track.name.includes("Spine")) {
      track.name = "spine2.quaternion";
    }

    return track;
  });

  useEffect(() => {
    let idleClipAction = mixer.clipAction(idleClips[0]);
    idleClipAction.play();

    let blinkClip = createAnimation(
      blinkData,
      morphTargetDictionaryBody,
      "HG_Body"
    );
    let blinkAction = mixer.clipAction(blinkClip);
    blinkAction.play();

    console.log("init");
    initialLogin();
  }, []);

  // Play animation clips when available
  useEffect(() => {
    if (playing === false) return;

    _.each(clips, (clip) => {
      let clipAction = mixer.clipAction(clip);
      clipAction.setLoop(THREE.LoopOnce);
      clipAction.play();
    });
  }, [playing]);

  useFrame((state, delta) => {
    mixer.update(delta);
  });

  return (
    <group name="avatar">
      <primitive object={gltf.scene} dispose={null} />
    </group>
  );
}

function initialLogin() {
  console.log("LOGIN");
  return axios.post(host + "/login");
}

function makeSpeechNew(text, audio) {
  console.log("NEW");
  const body = { text, audio };
  return axios.post(host + "/talk", body);
}

function makeSpeech(text) {
  return axios.post(host + "/talk_old", { text });
}

const STYLES = {
  area: {
    position: "absolute",
    bottom: "5px",
    left: "10px",
    right: "10px",
    zIndex: 500,
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "center",
  },
  text: {
    margin: "0px",
    width: "300px",
    maxWidth: "300px",
    minWidth: "300px",
    height: "100px",
    maxHeight: "100px",
    minHeight: "100px",
    padding: "5px",
    backgroundColor: "#f8f8f8",
    border: "1px solid #a6a6a6",
    color: "#532B61",
    fontSize: "0.9em",
    borderRadius: "0.375rem",
  },
  speak: {
    padding: "0.75rem 1.5rem",
    fontWeight: "bold",
    marginTop: "5px",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    marginBottom: "5px",
    display: "block",
    color: "#FFFFFF",
    fontSize: "1rem",
    border: "None",
    display: "flex",
    width: "25%",
    borderRadius: "0.375rem",
    justifyContent: "center",
    background: "#532B61",
  },
  area2: { position: "absolute", top: "5px", right: "15px", zIndex: 500 },
  label: { color: "#777777", fontSize: "0.8em" },
};

function App() {
  const audioPlayer = useRef();
  const [speak, setSpeak] = useState(false);
  const [text, setText] = useState(
    "Ciao sono Cintia chiedimi info sui dati su cui mi hai addestrato."
  );
  const [audioSource, setAudioSource] = useState(null);
  const [playing, setPlaying] = useState(false);
  const [recording, setRecording] = useState(false);
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audio, setAudio] = useState(null);
  const [endRecording, setEndRecording] = useState(false);
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  // End of play
  function playerEnded(e) {
    setAudioSource(null);
    setSpeak(false);
    setPlaying(false);
    setEndRecording(false);
    setAudio(null);
  }

  // Player is read
  function playerReady(e) {
    audioPlayer.current.audioEl.current.play();
    setPlaying(true);
  }

  let chunks = [];

  const initiateRecording = () => {
    chunks = [];
  };

  const onDataAvailable = (e) => {
    chunks.push(e.data);
  };

  const startRecording = () => {
    if (mediaRecorder) {
      console.log("recording");
      mediaRecorder.start();
      setRecording(true);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      setRecording(false);
    }
  };

  const sendAudioData = async (audioBlob) => {
    const reader = new FileReader();
    reader.readAsDataURL(audioBlob);
    reader.onloadend = async function () {
      const base64Audio = reader.result.split(",")[1];
      console.log("base64Audio", base64Audio);
      setAudio(base64Audio);
      setEndRecording(true);
      setLoading(true);
    };
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          const newMediaRecorder = new MediaRecorder(stream);
          newMediaRecorder.onstart = initiateRecording;
          newMediaRecorder.ondataavailable = onDataAvailable;
          newMediaRecorder.onstop = async () => {
            const audioBlob = new Blob(chunks, { type: "audio/webm" });
            try {
              await sendAudioData(audioBlob);
            } catch (error) {
              console.error(error);
              alert(error.message);
            }
          };
          setMediaRecorder(newMediaRecorder);
        })
        .catch((err) => console.error("Error accessing microphone:", err));
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      console.log(dimensions.width);

      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
      window.addEventListener("load", handleResize, false);
      window.addEventListener("resize", handleResize, false);
    };
    return () => {
      window.removeEventListener("load", handleResize, false);
      window.removeEventListener("resize", handleResize, false);
    };
  });

  return (
    <div className="full">
      <div style={STYLES.area}>
        <div style={{ flex: 1 }} />
        <div
          style={{
            flex: 1,
            display: "flex",
            pointerEvents: "auto",
            justifyContent: "center",
            position: "relative",
            // margin: "0 auto",
          }}
        >
          <button
            onClick={recording ? stopRecording : startRecording}
            style={{
              backgroundColor: recording ? "#db2b81" : "#532B61",
              color: "#FFF",
              padding: "1rem",
              fontWeight: 600,
              aspectRatio: 1,
              height: "50px",
              width: "50px",
              display: "flex",
              justifyContent: "center",
              borderRadius: "100%",
              border: "none",
              transition: "background-color 0.3s ease",
              opacity: loading || message ? 0.5 : 1,
              cursor: loading || message ? "not-allowed" : "pointer",
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <svg
              width="13"
              height="18"
              viewBox="0 0 10 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5 0.75C4.33696 0.75 3.70107 1.01339 3.23223 1.48223C2.76339 1.95107 2.5 2.58696 2.5 3.25V6.375C2.5 7.03804 2.76339 7.67393 3.23223 8.14277C3.70107 8.61161 4.33696 8.875 5 8.875C5.66304 8.875 6.29893 8.61161 6.76777 8.14277C7.23661 7.67393 7.5 7.03804 7.5 6.375V3.25C7.5 2.58696 7.23661 1.95107 6.76777 1.48223C6.29893 1.01339 5.66304 0.75 5 0.75ZM0.625 5.75C0.79076 5.75 0.949731 5.81585 1.06694 5.93306C1.18415 6.05027 1.25 6.20924 1.25 6.375C1.25 7.36956 1.64509 8.32339 2.34835 9.02665C3.05161 9.72991 4.00544 10.125 5 10.125C5.99456 10.125 6.94839 9.72991 7.65165 9.02665C8.35491 8.32339 8.75 7.36956 8.75 6.375C8.75 6.20924 8.81585 6.05027 8.93306 5.93306C9.05027 5.81585 9.20924 5.75 9.375 5.75C9.54076 5.75 9.69973 5.81585 9.81694 5.93306C9.93415 6.05027 10 6.20924 10 6.375C9.99998 7.59288 9.55551 8.76889 8.75 9.68234C7.94449 10.5958 6.83331 11.1839 5.625 11.3362V12.625C5.625 12.7908 5.55915 12.9497 5.44194 13.0669C5.32473 13.1842 5.16576 13.25 5 13.25C4.83424 13.25 4.67527 13.1842 4.55806 13.0669C4.44085 12.9497 4.375 12.7908 4.375 12.625V11.3362C3.16669 11.1839 2.05551 10.5958 1.25 9.68234C0.444492 8.76889 2.06712e-05 7.59288 0 6.375C0 6.20924 0.0658481 6.05027 0.183058 5.93306C0.300269 5.81585 0.45924 5.75 0.625 5.75Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
        <div
          style={{
            display: "flex",
            flex: 1,
            alignItems: "end",
            flexDirection: "column",
          }}
        >
          {/* <textarea
            rows={4}
            type="text"
            style={STYLES.text}
            value={text}
            onChange={(e) => setText(e.target.value.substring(0, 200))}
          /> */}
          <button
            style={{
              backgroundColor: "#532B61",
              color: "#FFF",
              padding: "1rem",
              fontWeight: 600,
              aspectRatio: 1,
              height: "50px",
              width: "50px",
              display: "flex",
              justifyContent: "center",
              borderRadius: "100%",
              border: "none",
              transition: "background-color 0.3s ease",
              opacity: speak ? 0.5 : 1,
              cursor: speak ? "not-allowed" : "pointer",
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => setSpeak(true)}
          >
            <svg
              width="18px"
              height="18px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="10" cy="6.75" r="4" fill="#fff" />
              <ellipse cx="10" cy="17.75" rx="7" ry="4" fill="#fff" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M18.357 2.36424C18.5702 2.00906 19.0309 1.89388 19.386 2.10699L19.0002 2.75011C19.386 2.10699 19.3857 2.10679 19.386 2.10699L19.3874 2.10783L19.389 2.10878L19.3927 2.11103L19.4023 2.11695C19.4096 2.12153 19.4189 2.12737 19.4299 2.13448C19.4519 2.14871 19.481 2.16809 19.5162 2.19272C19.5865 2.24194 19.6815 2.31244 19.7928 2.4052C20.0149 2.59029 20.3054 2.86678 20.5946 3.24283C21.1775 4.00057 21.7502 5.15746 21.7502 6.75011C21.7502 8.34277 21.1775 9.49966 20.5946 10.2574C20.3054 10.6334 20.0149 10.9099 19.7928 11.095C19.6815 11.1878 19.5865 11.2583 19.5162 11.3075C19.481 11.3321 19.4519 11.3515 19.4299 11.3657C19.4189 11.3729 19.4096 11.3787 19.4023 11.3833L19.3927 11.3892L19.389 11.3914L19.3874 11.3924C19.3871 11.3926 19.386 11.3932 19.0002 10.7501L19.386 11.3932C19.0309 11.6063 18.5702 11.4912 18.357 11.136C18.1448 10.7823 18.2581 10.324 18.6098 10.1097L18.6154 10.1062C18.6227 10.1014 18.6365 10.0923 18.656 10.0787C18.6951 10.0513 18.7563 10.0062 18.8325 9.9427C18.9854 9.81529 19.195 9.61678 19.4057 9.34283C19.8228 8.80057 20.2502 7.95746 20.2502 6.75011C20.2502 5.54277 19.8228 4.69966 19.4057 4.1574C19.195 3.88345 18.9854 3.68494 18.8325 3.55753C18.7563 3.49403 18.6951 3.44891 18.656 3.42157C18.6365 3.40792 18.6227 3.39878 18.6154 3.39406L18.6098 3.39053C18.2581 3.17625 18.1448 2.71793 18.357 2.36424Z"
                fill="#fff"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16.3293 4.4147C16.5146 4.04422 16.9651 3.89405 17.3356 4.07929L17.0002 4.75011C17.3356 4.07929 17.3352 4.07909 17.3356 4.07929L17.3372 4.08011L17.3389 4.08097L17.3426 4.08287L17.3512 4.08732L17.3728 4.09893C17.3891 4.10789 17.4091 4.11934 17.4324 4.13344C17.4787 4.16159 17.5383 4.20058 17.6064 4.25168C17.7423 4.35363 17.9153 4.5059 18.0858 4.71909C18.4345 5.15499 18.7502 5.81792 18.7502 6.75011C18.7502 7.6823 18.4345 8.34524 18.0858 8.78113C17.9153 8.99433 17.7423 9.1466 17.6064 9.24855C17.5383 9.29965 17.4787 9.33863 17.4324 9.36679C17.4091 9.38089 17.3891 9.39234 17.3728 9.40129L17.3512 9.4129L17.3426 9.41736L17.3389 9.41925L17.3372 9.42012C17.3368 9.42032 17.3356 9.42093 17.0064 8.76266L17.3356 9.42093C16.9651 9.60618 16.5146 9.45601 16.3293 9.08552C16.1464 8.71965 16.2906 8.27574 16.651 8.08634C16.6518 8.0859 16.6527 8.08533 16.6539 8.08461C16.6622 8.07956 16.6808 8.06776 16.7064 8.04855C16.758 8.00988 16.8351 7.9434 16.9145 7.84409C17.0658 7.65499 17.2502 7.31792 17.2502 6.75011C17.2502 6.1823 17.0658 5.84524 16.9145 5.65613C16.8351 5.55683 16.758 5.49035 16.7064 5.45168C16.6808 5.43246 16.6622 5.42066 16.6539 5.41562C16.6527 5.4149 16.6518 5.41432 16.651 5.41389C16.2906 5.22449 16.1464 4.78057 16.3293 4.4147Z"
                fill="#fff"
              />
            </svg>
          </button>
          <a
            href="https://www.puradigital.it"
            target="_blank"
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              fontSize: "0.5rem",
              background: "#fff",
              opacity: 0.8,
              padding: "2px",
              borderRadius: "2px",
              display: "flex",
              alignItems: "center",
              gap: 5,
              color: "black",
              textDecorationLine: "none",
            }}
          >
            All right reserved. Pura Digital srl{" "}
            <img
              src="/images/ui/pura_logo.png"
              style={{ width: "10px", height: "10px" }}
            />
          </a>
        </div>
      </div>

      <ReactAudioPlayer
        src={audioSource}
        ref={audioPlayer}
        onEnded={playerEnded}
        onCanPlayThrough={playerReady}
      />
      <Canvas
        dpr={2}
        onCreated={(ctx) => {
          ctx.gl.physicallyCorrectLights = true;
        }}
      >
        <OrthographicCamera makeDefault zoom={1000} position={[0, 1.65, 1]} />
        <Suspense fallback={null}>
          <Environment
            background={false}
            files="/images/photo_studio_loft_hall_1k.hdr"
          />
        </Suspense>
        {/* <Suspense fallback={null}>
          <Bg />
        </Suspense> */}
        <Suspense fallback={null}>
          <Avatar
            avatar_url="/model.glb"
            speak={speak}
            setSpeak={setSpeak}
            text={text}
            setAudioSource={setAudioSource}
            playing={playing}
            audio={audio}
            endRecording={endRecording}
            setEndRecording={setEndRecording}
            loading={loading}
            setLoading={setLoading}
          />
        </Suspense>
      </Canvas>
      <Loader dataInterpolation={(p) => `Loading... please wait`} />
    </div>
  );
}

function Bg() {
  const { viewport } = useThree();
  const texture = useTexture("/images/ui/bg-new.jpg");

  // Calcola il rapporto delle dimensioni dell'immagine di sfondo
  // const bgRatio = texture.image.width / texture.image.height;

  // Calcola le dimensioni del piano del background in base al rapporto
  const bgWidth = window.innerWidth;
  const bgHeight = window.innerHeight;

  return (
    <mesh position={[0, 1.8, -2]}>
      <planeBufferGeometry />
      <meshBasicMaterial map={texture} />
    </mesh>
  );
}

export default App;
